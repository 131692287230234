import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Form, Select, Tooltip } from "antd";
import _ from "lodash";

const FormItem = Form.Item;
const Option = Select.Option;
const DateRangePicker = Loader.loadBaseComponent("RangePicker");
const Search = Loader.loadBaseComponent("SearchInput");

let timer = null;

@withRouter
@Decorator.businessProvider("logManagement", "user")
@observer
@Form.create({
  onFieldsChange: (props, files) => {
    clearTimeout(timer);
    let { user } = props;
    let data = {};
    Object.keys(files).map(key => {
      data[key] = files[key].value;
    });
    let options = {
      offset: 0,
      centerIds: [_.cloneDeep(user.userInfo.operationCenterId)]
    };
    const newOptions = Object.assign({}, data, options);
    timer = setTimeout(() => props.search(newOptions), 100);
  }
})
class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionModelTypeCopy: [],
      actionFeaturnTypeCopy: [],
      userInfo: {},
      dateBegin: null,
      dateEnd: moment(this.props.logManagement.searchData.endTime)
    };
  }

  componentDidMount() {
    let { form, actionModelType } = this.props;
    let actionModelTypeCopy = _.cloneDeep(actionModelType);
    actionModelTypeCopy.unshift({ code: null, text: "全部" });
    this.actionPlatTypeTl = null;

    this.setState({
      actionModelTypeCopy: actionModelTypeCopy
    });
  }

  actionPlatType = options => {
    this.actionPlatTypeTl = options; // 记录目前为哪个端的操作
    const { form, appList, actionModelType, actionFeaturnType, logInfoDict } = this.props;
    var actionFeaturnTypeCopy = [];
    var actionModelTypeCopy = [];
    if (options === undefined) {
      form.setFieldsValue({
        modules: undefined,
        functions: undefined
      });
      actionModelTypeCopy = [];
      actionFeaturnTypeCopy = [];
    } else {
      form.setFieldsValue({
        modules: null,
        functions: null
      });
      if (options) {
        if (options == "userAgentMobile") {
          _.unionBy([], appList, "code").map(v => {
            if (v.parent) {
              actionFeaturnTypeCopy.push(v);
            } else {
              actionModelTypeCopy.push(v);
            }
          });
        } else {
          logInfoDict.map(v => {
            if (v.parent) {
              actionFeaturnTypeCopy.push(v);
            } else {
              actionModelTypeCopy.push(v);
            }
          });
        }
      } else {
        actionModelTypeCopy = _.cloneDeep(actionModelType);
        actionFeaturnTypeCopy = _.cloneDeep(actionFeaturnType);
      }
      actionModelTypeCopy.unshift({ code: null, text: "全部" });
      actionFeaturnTypeCopy.unshift({ code: null, text: "全部" });
    }
    this.setState({
      actionModelTypeCopy,
      actionFeaturnTypeCopy
    });
  };
  menuTypeChange = options => {
    const { form, actionFeaturnType } = this.props;
    var actionFeaturnTypeCopy = [];
    if (options === undefined) {
      form.setFieldsValue({
        actionFeaturnTypeCopy: null,
        functions: undefined
      });
      actionFeaturnTypeCopy = [];
    } else {
      form.setFieldsValue({
        actionFeaturnTypeCopy: null,
        functions: null
      });
      if (options) {
        // options选中一个模块的情况 -- 1.首先判断操作端，再判断为哪个模块
        let dataResource = actionFeaturnType;
        if (this.actionPlatTypeTl === "userAgentWeb") {
          dataResource = this.props.logInfoDict;
        }
        if (this.actionPlatTypeTl === "userAgentMobile") {
          dataResource = this.props.appList;
        }
        actionFeaturnTypeCopy = dataResource.filter(v => v.parent === options);
        actionFeaturnTypeCopy.unshift({ code: null, text: "全部" });
      } else {
        // options为null的情况
        actionFeaturnTypeCopy = actionFeaturnType;
        if (this.actionPlatTypeTl === "userAgentWeb") {
          actionFeaturnTypeCopy = this.props.logInfoDict.filter(v => v.parent);
        }
        if (this.actionPlatTypeTl === "userAgentMobile") {
          actionFeaturnTypeCopy = this.props.appList.filter(v => v.parent);
        }
      }
    }
    this.setState({
      actionFeaturnTypeCopy
    });
  };
  getTreeData = data => {
    let list = [];
    data.map(v => {
      let childrenList = [];
      if (v.children && v.children.length > 0) {
        childrenList = this.getTreeData(v.children);
      }
      list.push({
        title: v.name,
        value: v.id + "",
        key: v.id,
        children: childrenList
      });
      return list;
    });
    return list;
  };
  timeChange = (type, value) => {
    const { logManagement } = this.props;
    let { dateBegin, dateEnd } = this.state;
    let startTime = moment(new Date(dateBegin)),
      endTime = moment(new Date(dateEnd));
    if (type === "startTime") {
      startTime = moment(new Date(value));
      this.setState({ dateBegin: value });
    } else {
      endTime = moment(new Date(value));
      this.setState({ dateEnd: value });
    }
    startTime = new Date(startTime).valueOf();
    endTime = new Date(endTime).valueOf();
    let timeOptions = { startTime, endTime };
    logManagement.editSearchData(timeOptions);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { actionPlatType } = this.props;
    const { actionFeaturnTypeCopy, dateBegin, dateEnd, actionModelTypeCopy } = this.state;
    return (
      <div className="logger-search-form">
        <Form layout="vertical">
          <FormItem>
            <Tooltip title="请选择操作端">
              {getFieldDecorator("userAgent", { initialValue: null })(
                <Select allowClear={true} onChange={this.actionPlatType} placeholder="请选择操作端">
                  {actionPlatType.map(v => (
                    <Option key={v.code} value={v.code} title={v.text}>
                      {v.text}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </FormItem>
          <FormItem>
            <Tooltip title="操作模块">
              {getFieldDecorator("modules")(
                <Select allowClear={true} onChange={this.menuTypeChange} placeholder="操作模块">
                  {actionModelTypeCopy.map(v => (
                    <Option key={v.code} value={v.code} title={v.text}>
                      {v.text}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </FormItem>
          <FormItem>
            <Tooltip title="操作功能">
              {getFieldDecorator("functions")(
                <Select allowClear={true} placeholder="操作功能">
                  {actionFeaturnTypeCopy.map(v => (
                    <Option key={v.code} value={v.code} title={v.text}>
                      {v.text}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </FormItem>
          <FormItem className="rangeTime">
            <Tooltip title="请选择时间段">
              {getFieldDecorator("rangeTime")(<DateRangePicker allowClear={false} startTime={dateBegin} endTime={dateEnd} onChange={this.timeChange} />)}
            </Tooltip>
          </FormItem>
          <FormItem className="log-search-text">
            <Tooltip title="请输入操作人姓名或描述查询">
              {getFieldDecorator("keywords", {
                rules: [{ max: 100, message: "描述长度最大不能超过100" }, { whitespace: true, message: "描述不能为空白" }]
              })(<Search enterButton placeholder="请输入操作人姓名或描述查询" />)}
            </Tooltip>
          </FormItem>
        </Form>
      </div>
    );
  }
}

// export default Form.create({})(SearchForm);
export default SearchForm;
